import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit',
      name: 'deposit',
      component: () => import('@/views/deposit/view.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit/kbank',
      name: 'kbank',
      component: () => import('@/views/deposit/bank/kbank.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit/kbank2',
      name: 'kbank2',
      component: () => import('@/views/deposit/bank/kbank2.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit/scb',
      name: 'scb',
      component: () => import('@/views/deposit/bank/scb.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit/scb2',
      name: 'scb2',
      component: () => import('@/views/deposit/bank/scb2.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit/truemoney-wallet',
      name: 'wallet',
      component: () => import('@/views/deposit/bank/wallet.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit/crypto',
      name: 'crypto',
      component: () => import('@/views/deposit/bank/crypto.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/gamelobby',
      name: 'gamelobby',
      component: () => import('@/views/gameAll/gamelobby.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/games-all/:productId/:productName',
      name: 'games-all',
      component: () => import('@/views/gameAll/gameAll.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/withdraw',
      name: 'withdraw',
      component: () => import('@/views/withdraw/view.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/account/view.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/deposit-fixed',
      name: 'deposit-fixed',
      component: () => import('@/views/deposit-fixed/view.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/invite-friend',
      name: 'invite-friend',
      component: () => import('@/views/invite-friend/view.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/promotions',
      name: 'promotions',
      component: () => import('@/views/promotions/view.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/lucky-wheel',
      name: 'lucky-wheel',
      component: () => import('@/views/wheel/wheel.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
        // redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authenticate/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/newLogin/login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // {
    //   path: '/register/:ref',
    //   name: 'register',
    //   component: () => import('@/views/authenticate/Register.vue'),
    //   meta: {
    //     layout: 'full',
    //     resource: 'Auth',
    //   },
    // },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
